import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";
import {
  combineReducers,
  configureStore,
  Tuple,
  UnknownAction,
  Dispatch,
  Middleware,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import logger from "redux-logger";
import { createReduxHistoryContext } from "redux-first-history";

import { loadState, saveState } from "./localStorage";
import sagas from "./sagas";
import { isTokenExpired } from "../utils/utils";
import { LOGOUT } from "../constants/actionTypes";

import token from "../reducers/token";
import devices from "../reducers/devices";
import deviceTasks from "../reducers/deviceTasks";
import groups from "../reducers/groups";
import users from "../reducers/users";
import map from "../reducers/map";
import register from "../reducers/register";
import admin from "../reducers/admin";
import scale from "../reducers/scale";
import mfa from "../reducers/mfa";

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();
const persistedState = loadState();
if (
  persistedState &&
  persistedState.token &&
  isTokenExpired(persistedState.token)
) {
  persistedState.token = undefined;
}

const reducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return combineReducers({
    token,
    devices,
    deviceTasks,
    groups,
    users,
    map,
    register,
    admin,
    router: routerReducer,
    scale,
    mfa,
  })(state, action);
};

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  reducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => {
    const middleware: Tuple<
      Middleware<{}, any, Dispatch<UnknownAction>>[]
    > = getDefaultMiddleware().concat(
      sagaMiddleware,
      routerMiddleware as Middleware<{}, any, Dispatch<UnknownAction>>
    );

    // Conditionally add another middleware in dev
    if (process.env.NODE_ENV !== "production") {
      middleware.push(logger);
    }

    return middleware;
  },
  // Turn off devtools in prod, or pass options in dev
  devTools: process.env.NODE_ENV === "production" ? false : {},
});

store.subscribe(
  throttle(() => {
    saveState({
      token: store.getState().token,
    });
  }, 1000)
);
sagaMiddleware.run(sagas);

export const history = createReduxHistory(store);

export default store;
